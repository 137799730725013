<div class="modal-box" [class.container]="!modal">
    <div class="modal-title" *ngIf="modal">Notifications Settings</div>
    <button mat-icon-button type="button" *ngIf="modal" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
    <div class="setting">
        <div class="setting__title">
            <ng-container *ngIf="!modal">
                <h2 class="modal-h2">Notifications Settings</h2>
            </ng-container>
            <p class="modal-p">Recieve notifications about important activity in your account.</p>
        </div>
        <div class="setting__items fl-grow">
            <div class="modal-switch">
                <label class="waw-switch">
                    <input type="checkbox" name="unread" [ngModel]="getNtfStgs('unread')" (ngModelChange)="setNtfStgs('unread', $event)">
                    <span class="track">
                        <span class="handle"></span>
                    </span>
                    <div class="waw-switch-text">Only show unread</div>
                </label>
            </div>
            <div class="modal-switch">
                <label class="waw-switch">
                    <input type="checkbox" name="popup" [ngModel]="getNtfStgs('popup')" (ngModelChange)="setNtfStgs('popup', $event)">
                    <span class="track">
                        <span class="handle"></span>
                    </span>
                    <div class="waw-switch-text">Show new in popup</div>
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngIf="notifications_are_supported">
        <div class="setting">
            <div class="setting__title">
                <h2 class="modal-h2">Desktop notifications</h2>
                <p class="modal-p">These notifications keeps you informed without having to constantly check the application.</p>
            </div>
            <div class="setting__items fl-grow">
                <div class="modal-switch">
                    <label class="waw-switch">
                        <input type="checkbox" name="popup" [ngModel]="firebase_token" (ngModelChange)="setFirebaseToken($event)">
                        <span class="track">
                            <span class="handle"></span>
                        </span>
                        <div class="waw-switch-text">Allow desktop notifications</div>
                    </label>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="setting">
        <div class="setting__title">
            <h2 class="modal-h2">Notification preferences</h2>
            <p class="modal-p">Select which notifications you want to receive.</p>
        </div>
        <div class="setting__items fl-grow">
            <ng-container *ngIf="!!us.role">
                <label class="waw-checkbox mb15" *ngFor="let notif of ps.platform.data.notification?.staff|each:2">
                    <input type="checkbox" [ngModel]="getNtfStgs(notif.value.type)" (ngModelChange)="setNtfStgs(notif.value.type, $event)">
                    <span class="checkmark"></span>
                    <span class="waw-checkbox__text">{{notif.value.type}}</span>
                </label>
            </ng-container>
            <ng-container *ngIf="!us.role">
                <label class="waw-checkbox mb15" *ngFor="let notif of ps.platform.data.notification?.client|each:2">
                    <input type="checkbox" [ngModel]="getNtfStgs(notif.value.type)" (ngModelChange)="setNtfStgs(notif.value.type, $event)">
                    <span class="checkmark"></span>
                    <span class="waw-checkbox__text">{{notif.value.type}}</span>
                </label>
            </ng-container>
        </div>
    </div>
</div>