import { NgModule } from '@angular/core';
import { SearchPipe } from './search.pipe';
import { EachPipe } from './each.pipe';
import { SafePipe } from './safe.pipe';
import { MongodatePipe } from './mongodate.pipe';
import { AppointmentsPipe } from './appointments.pipe';
import { TimePipe } from './time.pipe';
import { AppointmentsFilterPipe } from './appointmentsFilter.pipe';
import { NotesFilterPipe } from './notes-filter.pipe';
import { StaffByIdPipe } from './staffById.pipe';
import { PriceByStatusPipe } from './price-by-status.pipe';
import { SearchByClientPipe } from './search-by-client.pipe';
import { SortPipe } from './sort.pipe';
import { LastModifiedPipe } from './lastModified.pipe'
import { CheckoutsFilterPipe } from './checkoutsFilter.pipe'
import { WorkingusersPipe } from './working-users.pipe';
import { HoursPipe } from './hours.pipe';
import { RelativeTimePipe } from './relativeTime.pipe';
import { AppointmentsDatePipe } from './appointmentsDate.pipe';
import { CheckoutsDateLocationPipe } from './checkoutsDateLocation.pipe'
import { FormatTimePipe } from './formatTime.pipe'
import { NotePipe } from './note.pipe'
@NgModule({
	exports: [
		SearchPipe, 
		EachPipe, 
		SafePipe, 
		MongodatePipe, 
		AppointmentsPipe, 
		TimePipe, 
		AppointmentsFilterPipe, 
		NotesFilterPipe, 
		StaffByIdPipe, 
		PriceByStatusPipe, 
		SearchByClientPipe, 
		SortPipe, 
		LastModifiedPipe, 
		CheckoutsFilterPipe, 
		WorkingusersPipe, 
		HoursPipe,
		RelativeTimePipe, 
		AppointmentsDatePipe, 
		CheckoutsDateLocationPipe, 
		FormatTimePipe,
		NotePipe
	],
	declarations: [
		SearchPipe, 
		EachPipe, 
		SafePipe, 
		MongodatePipe, 
		AppointmentsPipe, 
		TimePipe, 
		AppointmentsFilterPipe, 
		NotesFilterPipe, 
		StaffByIdPipe, 
		PriceByStatusPipe, 
		SearchByClientPipe, 
		SortPipe, 
		LastModifiedPipe, 
		CheckoutsFilterPipe, 
		WorkingusersPipe, 
		HoursPipe,
		RelativeTimePipe, 
		AppointmentsDatePipe, 
		CheckoutsDateLocationPipe, 
		FormatTimePipe,
		NotePipe
	],
	providers: [
		StaffByIdPipe
	]
})
export class PipesModule { }
