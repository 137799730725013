import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CoreService, ModalService, MongoService } from 'wacom';
import { UserService, HashService, ServiceService, LoaderService } from '@services';
import { Router } from '@angular/router';
import { flyAnimation } from 'src/app/common/animation/animation.component';

@Component({
	selector: 'app-staff',
	templateUrl: './staff.component.html',
	styleUrls: ['./staff.component.scss'],
	animations: [flyAnimation]
})
export class StaffComponent implements OnInit {
	public searching = '';
	public services:any;
	public date: any;
	public tab = 'information';
	public tabs: any = [
		{
			label: 'Staff information',
			title: 'information'
		}, {
			label: 'Working hours',
			title: 'working-hours'
		}
	];
	@ViewChild('working', {static: false}) working:any;
	set_user_id(user_id){
		if(!this.working){
			return setTimeout(()=>{
				this.set_user_id(user_id);
			}, 100);
		}
		this.working.set_user_id(user_id);
	}
	constructor(private router: Router, public hash: HashService,
		public us: UserService, private modal: ModalService, 
		public ss: ServiceService,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		let user_id = router.url.replace('/staff/', '').replace('/staff', '');
		if(user_id) this.set_user_id(user_id);
		this.us.headerTitle = 'Staff';
		this.us.headerSubtitle = '';
		this.hash.on('tab', tab => this.tab = tab || 'information');
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	public dates = [];
	public monthDate;
	public view: boolean = true;
	public weekStart: number  = this.getFirstDay(new Date()).getTime();
	public month = new Date().getMonth();
	public year = new Date().getFullYear();
	getFirstDay(d) {
		d = new Date(d);
		var day = d.getDay();
  		var diff = day === 0 ? -6 : 1 - day;
		return new Date(d.setDate(d.getDate() + diff));
	}
	onDateChanged(value) {
		this.weekStart = new Date(value.start).getTime();
	}
	changeMonth(value) {
		this.month = value.month;
		this.year = value.year;
	}
	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		this.tab = this.hash.get('tab') || 'information';
    }
	openStaff(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'staff'});		
	}
	openHours(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'workingHours'});		
	}
	openClock(){
		const start = this.view ? new Date(this.weekStart) : new Date(this.year, this.month, 1);
		const end = this.view ? new Date(start) : new Date(this.year, this.month + 1, 0);
		if(this.view) end.setDate(end.getDate() + 6);
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'clock', selected_date: {start: start, end: end}});		
	}
}