import { Component, Input, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ModalService, MongoService } from 'wacom';
import { NoteService, AppointmentService, UserService, ServiceService, LoaderService } from '@services';
import { NoteComponent } from 'src/app/modals/note/note.component';
import { Router,ActivatedRoute } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, AfterViewInit {
	@Input() searching: string = '';
	@Input() services: any;
	@Input() date;
	public sort_type:any = {};
	constructor(public modal: ModalService, public nt: NoteService, public ap: AppointmentService, public us: UserService, 
		public ss: ServiceService,	private route: ActivatedRoute, private router: Router, private currencyPipe: CurrencyPipe, private datePipe: DatePipe,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
	}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('user note appointment service', () => {
			this.loader.remove();
		});
	}
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	getTooltipAppointment(note) {
		if(note.appointment && typeof note.appointment === 'object') {
			const start = new Date();
			start.setHours(note.appointment?.start.split(':')[0]);
			start.setMinutes(note.appointment?.start.split(':')[1]);
			const end = new Date();
			end.setHours(note.appointment?.end.split(':')[0]);
			end.setMinutes(note.appointment?.end.split(':')[1]);
			return `${this.ss._services[note.appointment?.service]?.name}
			${this.datePipe.transform(start, 'shortTime')} - ${this.datePipe.transform(end, 'shortTime')}`;
		} else {
			return '';
		}
	}
}
