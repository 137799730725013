import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';
import { UserService } from './user.service';
@Injectable({
	providedIn: 'root'
})
export class NoteService {
	public notes: any = [];
	public staff_notes = [];
	public client_notes = [];
	public _notes: any = {};
	public note_by_client: any = {};
	constructor(private mongo: MongoService, private alert: AlertService, private us: UserService) { 
		this.notes = mongo.get('note', {
			populate: [{
				field: 'appointment',
				part: 'appointment'
			}],
			groups: {
				note_by_client: {
					field: function(doc){
						return doc.client;
					},
					sort: this.mongo.sortDescId()
				},
			}, query: {
				staff_notes: function(doc){
					return doc.staff&&doc.client&&(!!us.role || us.is.admin || us.is.owner);
				},
				client_notes: function(doc){
					return doc.client;
				}
			}
		}, (arr, obj) => {
			this.note_by_client = obj.note_by_client;
			this.staff_notes = obj.staff_notes;
			this.client_notes = obj.client_notes;
			this._notes = obj;
		});
	}
	create(note:any={}, cb: any = () => {}) {
		if(note._id) return this.save(note, cb);
		this.mongo.create('note', note, created=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Note has been added.'
			});
		}); 
	}
	update(note, cb: any = () => {}) {
		this.mongo.afterWhile(note, ()=> {
			this.save(note, cb);
		});
	}
	save(note, cb: any = () => {}){
		this.mongo.update('note', note, (updated)=>{
			if(typeof cb === 'function') cb(updated);
			this.alert.show({
				text: 'Note has been updated.'
			});
		});
	}
	delete(note, cb: any = () => {}) {
		this.mongo.delete('note', note, (deleted)=>{
			if(typeof cb === 'function') cb(deleted);
			this.alert.show({
				text: 'Note has been deleted.'
			});
		});
	}
}
