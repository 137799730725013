import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, LocationService, HashService, LoaderService } from '@services';
import { MongoService } from 'wacom';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public tab: string = 'overview';

	public tabs: any = [
		'overview',
		'appointments',
		'sales',
		'clients',
		'staff'
	];

	public selector: any = {
		start: new Date(),
		end: new Date()
	};

	public interval: string = 'day';

	public location: any = null;

	constructor(
		public us: UserService, 
		public loc: LocationService,
		public hash: HashService,
		private router: Router,
		private route: ActivatedRoute,
		private datePipe: DatePipe,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef
	) {
		this.us.headerTitle = 'dashboard';
		this.us.headerSubtitle = '';
		this.hash.on('tab', tab => {
			if(tab) this.hash.set('tab', tab);
			this.tab = tab || 'overview'
		});

		this.mongo.on('location', () => {
			if(this.route.snapshot.queryParams.location && this.loc._locations[this.route.snapshot.queryParams.location]) {
				this.location = this.route.snapshot.queryParams.location;
			}
		});
		if(this.route.snapshot.queryParams.interval && this.route.snapshot.queryParams.selector) {
			this.interval = this.route.snapshot.queryParams.interval;
			switch(this.interval) {
				case 'day':
					this.selector = {
						start: new Date(this.route.snapshot.queryParams.selector),
						end: new Date(this.route.snapshot.queryParams.selector)
					};
					break;
				case 'week': 
					const d_week = new Date(this.route.snapshot.queryParams.selector);
					const day_week = d_week.getDay();
					const diff_week = day_week === 0 ? -6 : 1 - day_week;
					const start_week = new Date(d_week.setDate(d_week.getDate() + diff_week));
					const end_week = new Date(d_week.setDate(d_week.getDate() + 6));
					this.selector = {
						start: start_week,
						end: end_week
					};
					break;
				case 'month':
					const start_month = new Date(new Date(this.route.snapshot.queryParams.selector).getFullYear(), new Date(this.route.snapshot.queryParams.selector).getMonth(), 1);
					const end_month = new Date(new Date(this.route.snapshot.queryParams.selector).getFullYear(), new Date(this.route.snapshot.queryParams.selector).getMonth() + 1, 0);
					this.selector = {
						start: start_month,
						end: end_month
					};
					break;
				case 'quarter':
					const d_quarter = new Date(this.route.snapshot.queryParams.selector);
					if (d_quarter.getMonth() >= 0 && d_quarter.getMonth() <= 2) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 0, 1),
							end: new Date(d_quarter.getFullYear(), 3, 0)
						};
					} else if (d_quarter.getMonth() >= 3 && d_quarter.getMonth() <= 5) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 3, 1),
							end: new Date(d_quarter.getFullYear(), 6, 0)
						};
					} else if (d_quarter.getMonth() >= 6 && d_quarter.getMonth() <= 8) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 6, 1),
							end: new Date(d_quarter.getFullYear(), 9, 0)
						};
					} else {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 10, 1),
							end: new Date(d_quarter.getFullYear(), 12, 0)
						};
					}
					break;
				case 'year':
					const d_year = new Date(this.route.snapshot.queryParams.selector);
					this.selector = {
						start: new Date(d_year.getFullYear(), 0, 1),
						end: new Date(d_year.getFullYear(), 11, 31)
					};
					break;
			}
		}
	}

	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}

	periodChange(event) {
		this.selector = {
			start: event.selector.start,
			end: event.selector.end
		};
		this.interval = event.interval;
		this.router.navigate([],{ 
			queryParams: { 
				interval: this.interval,
				selector: this.datePipe.transform(this.selector.start, 'yyyy-MM-dd')
			}, 
			queryParamsHandling: 'merge', 
			fragment: 'tab=' + this.tab 
		});
	}

	locationChange(event) {
		const queryParams = { ...this.route.snapshot.queryParams };

  		if(event) queryParams.location = event;
		else delete queryParams['location'];

		this.router.navigate([],{ 
			queryParams: queryParams, 
			fragment: 'tab=' + this.tab 
		});
	}
}