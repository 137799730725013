import { Component, OnInit, Injectable, AfterViewInit, ElementRef } from '@angular/core';
import { UserService, RoleService, CheckoutService, AppointmentService, ServiceService, DiscountService, LoaderService } from '@services';
import { AlertService, MongoService } from 'wacom';
import { HttpClient } from '@angular/common/http';
import * as es6printJS from "print-js";
import { saveAs } from "file-saver"
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `First page`;
  itemsPerPageLabel = `Items per page:`;
  lastPageLabel =`Last page`;
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }
}

@Component({
	selector: 'app-checkouts',
	templateUrl: './checkouts.component.html',
	styleUrls: ['./checkouts.component.scss'],
  	providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class CheckoutsComponent implements OnInit, AfterViewInit {
	public search: any = '';
	public sort_type:any = {};
	public searching;
	public day;
	public type;
	public config: any = {
		page: 1,
		perPage: 100
	}
	
	constructor(
		public us: UserService, 
		public cs: CheckoutService, 
		public aps: AppointmentService, 
		public ss: ServiceService, 
		public ds: DiscountService, 
		private http: HttpClient, 
		private alert: AlertService,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef
	) {
		this.us.headerTitle = 'Sales';
		this.us.headerSubtitle = 'Admin';
	}

	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));

		this.sort_type = {
			title: 'created_at',
			direction: 'desc'
		}

		if(window.innerWidth <= 1024 && window.innerWidth > 767 && this.config.perPage != 50) this.config.perPage = 50
		if(window.innerWidth <= 767 && this.config.perPage != 25) return this.config.perPage = 25
	}
	ngAfterViewInit() {
		this.mongo.on('checkout discount appointment user service', () => {
			this.loader.remove();
		});
	}

	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	printInvoice(check: any) {
		if(typeof window.print != 'function') {
			return this.alert.show({
				type: 'error',
				text: 'Printing is not supported on this device'
			});
		}
		this.http.get('/api/invoice/file/'+check._id, { responseType: 'blob'}).subscribe(blob => {
			blob.text().then(value => {
				if(value != 'false') es6printJS(URL.createObjectURL(blob), 'pdf');
				else this.alert.show({
					type: 'error',
					text: 'Invoice not found.'
				});
			});
		});
	}
	downloadInvoice(check: any) {
		this.http.get('/api/invoice/file/'+check._id, { responseType: 'blob'}).subscribe(blob => {
			blob.text().then(value => {
				if(value != 'false') {
					this.http.get('/api/invoice/name/'+check._id, { responseType: 'text'}).subscribe(name => {
						saveAs(blob, name);
					});
				}
				else this.alert.show({
					type: 'error',
					text: 'Invoice not found.'
				});
			});
		});
	}

	handlePageEvent(event) {
		this.config.page = event.pageIndex + 1;
	}
}