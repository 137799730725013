import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MongoService } from 'wacom';
@Pipe({
	name: 'checkoutsDateLocation'
})
export class CheckoutsDateLocationPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}
	transform(checkouts, date, location = null, refresh?) {
		if(!checkouts) return [];
		if(!date.start) date.start = new Date();
		if(!date.end) date.end = date.start;

		var res = [];

		for(let d = new Date(date.start); d <= new Date(date.end); d.setDate(d.getDate() + 1)) {
			let formatted = this.datePipe.transform(d, 'shortDate');
			if(checkouts[formatted]) {
				if(location) {
					res = res.concat(checkouts[formatted].filter((appt) => {
						return appt.location === location;
					}));
				} else {
					res = res.concat(checkouts[formatted]);
				}
			}
		}

		return res;
	}
}
