import { Component, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { AppointmentService, CheckoutService, LoaderService, ServiceService, UserService } from '@services';
import { DatePipe } from '@angular/common';
import { MongoService } from 'wacom';
import { AppointmentsDatePipe, CheckoutsDateLocationPipe } from '@pipes';

@Component({
	selector: 'app-dashboard-clients',
	templateUrl: './dashboard-clients.component.html',
	styleUrls: ['./dashboard-clients.component.scss', '../dashboard.component.scss']
})
export class DashboardClientsComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public new_clients_total: number = 0;

	public new_clients_by_sources: any = [
		{
			name: 'Walk in',
			value: 0,
		}, {
			name: 'Socials',
			value: 0,
		}, {
			name: 'Referral',
			value: 0,
		}, {
			name: 'Advertisement',
			value: 0,
		}
	];

	public clients_total: number = 0;

	public clients_by_age: any = [
		{
			name: 'Under 18',
			value: 0,
		}, {
			name: '18-34',
			value: 0,
		}, {
			name: '35-49',
			value: 0,
		}, {
			name: '50-64',
			value: 0,
		}, {
			name: '65 and Over',
			value: 0,
		}
	];

	public clientsLineChart: any = [
		{
			"name": "Clients",
			"series": []
		}
	];

	public yAxisClients = this.yAxisClientsFormatting.bind(this);

	public maxYValue: number = 0;

	public topClientsColumns: any = [{ title: 'client', field: 'client_name' }, 'appointments', 'revenue', { title: 'total tips', field: 'sum_tips' }];

	public topClientsRows: any = [];

	public noShowsColumns: any = [{ title: 'client', field: 'client_name' }, { title: 'no-shows', field: 'no_shows' }, { title: 'no-shows value', field: 'no_shows_value' }, 'appointments', 'revenue'];

	public noShowsRows: any = [];

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
		newClients: false,
		clientsAge: false,
		clientsLineChart: false,
		topClients: false,
		noShowsClients: false,
	};

	constructor(
		public us: UserService, 
		private datePipe: DatePipe,
		private mongo: MongoService,
		public aps: AppointmentService, 
		private appointmentsDatePipe: AppointmentsDatePipe,
		public cs: CheckoutService,
		private checkoutsDateLocationPipe: CheckoutsDateLocationPipe,
		private ss: ServiceService,
		private loader: LoaderService,
		private eref: ElementRef
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.mongo.on('user', () => {
			this.initializeNewClients();
			this.initializeClientsAge();
			this.initializeClientsLineChart();
		});
		this.mongo.on('user appointment checkout', () => {
			this.initializeTopClients();
			this.initializeNoShowsClients();
		});
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			this.informationLoaded = {
				newClients: false,
				clientsAge: false,
				clientsLineChart: false,
				topClients: false,
				noShowsClients: false,
			};
	
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					this.refresh();
				}
			}, 10);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user appointment service checkout', () => {
			this.afterViewInit = true;
			let waitForInformationLoading = setInterval(() => {
				if (Object.values(this.informationLoaded).every(value => value === true)) {
					clearInterval(waitForInformationLoading);
					this.loader.remove();
				}
			}, 1);
		});
	}

	refresh() {
		this.initializeNewClients();
		this.initializeClientsAge();
		this.initializeClientsLineChart();
		this.initializeTopClients();
		this.initializeNoShowsClients();

		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	initializeNewClients() {
		this.new_clients_total = 0;
		this.new_clients_by_sources = [
			{
				name: 'Walk in',
				value: 0,
			}, {
				name: 'Socials',
				value: 0,
			}, {
				name: 'Referral',
				value: 0,
			}, {
				name: 'Advertisement',
				value: 0,
			}
		];

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
			if(this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
				this.new_clients_total += this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]?.length;
				for(let client of this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
					if(client.data.source) {
						if(this.new_clients_by_sources.find(s => s.name == client.data.source)) this.new_clients_by_sources.find(s => s.name == client.data.source).value++;
					}
				}
			}
		}

		this.informationLoaded.newClients = true;
	}

	initializeClientsAge() {
		this.clients_total = 0;
		this.clients_by_age = [
			{
				name: 'Under 18',
				value: 0,
			}, {
				name: '18-34',
				value: 0,
			}, {
				name: '35-49',
				value: 0,
			}, {
				name: '50-64',
				value: 0,
			}, {
				name: '65 and Over',
				value: 0,
			}
		];

		const clients = [];

		for (let appt of this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location)) {
			if (!clients.find(c => c == appt.client)) {
				clients.push(appt.client);
				this.clients_total++;
				if (this.us._users[appt.client]?.data?.birthday?.singleDate?.jsDate) {
					const birthDate = new Date(this.us._users[appt.client]?.data?.birthday?.singleDate?.jsDate);
					const currentDate = new Date();
					let age = currentDate.getFullYear() - birthDate.getFullYear();
					
					if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
						age--;
					}

					if (age < 18) {
						this.clients_by_age.find(i => i.name === 'Under 18').value++;
					} else if (age >= 18 && age <= 34) {
						this.clients_by_age.find(i => i.name === '18-34').value++;
					} else if (age >= 35 && age <= 49) {
						this.clients_by_age.find(i => i.name === '35-49').value++;
					} else if (age >= 50 && age <= 64) {
						this.clients_by_age.find(i => i.name === '50-64').value++;
					} else if (age >= 65) {
						this.clients_by_age.find(i => i.name === '65 and Over').value++;
					}
				}
			}
		}

		this.informationLoaded.clientsAge = true;
	}

	yAxisClientsFormatting(value) {
		return value;
	}

	initializeClientsLineChart() {
		this.maxYValue = 0;
		this.clientsLineChart = [
			{
				"name": "Clients",
				"series": []
			}
		];

		switch(this.interval) {
			case 'day':
				for(let i = 0; i < 24; i++) {
					let buf = 0;
					let clients = [];
					this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).forEach((obj) => {
						const start = obj.start.split(':')[0];
						const end = obj.end.split(':')[0];
						// start == i || (end == i && obj.end.split(':')[1] > 0) || (start <= i && (end >= i && obj.end.split(':')[1] > 0))
						if ((start == i || (end == i && obj.end.split(':')[1] > 0) || (start <= i && (end >= i && obj.end.split(':')[1] > 0))) && !clients.find(c => c == obj.client)) {
							clients.push(obj.client);
							buf++;
						}
					});
					let d = new Date(this.selector.start);
					d.setHours(i, 0, 0);

					if(buf > this.maxYValue) this.maxYValue = buf;
					
					this.clientsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortTime'),
						value: buf,
						title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
					});
				}
				break;
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					let clients = [];
					this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location).forEach((obj) => {
						if (!clients.find(c => c == obj.client)) {
							clients.push(obj.client);
							buf++;
						}
					});

					if(buf > this.maxYValue) this.maxYValue = buf;

					this.clientsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: buf,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'month':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					let clients = [];
					this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location).forEach((obj) => {
						if (!clients.find(c => c == obj.client)) {
							clients.push(obj.client);
							buf++;
						}
					});

					if(buf > this.maxYValue) this.maxYValue = buf;
					
					this.clientsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: buf,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);

					let buf = 0;
					let clients = [];
					this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location).forEach((obj) => {
						if (!clients.find(c => c == obj.client)) {
							clients.push(obj.client);
							buf++;
						}
					});

					if(buf > this.maxYValue) this.maxYValue = buf;
										
					this.clientsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'MMM'),
						value: buf,
						title: this.datePipe.transform(d, 'MMMM')
					});
				}
				break;
			case 'quarter':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 7)) {
					let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);
					
					let buf = 0;
					let clients = [];
					this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location).forEach((obj) => {
						if (!clients.find(c => c == obj.client)) {
							clients.push(obj.client);
							buf++;
						}
					});

					if(buf > this.maxYValue) this.maxYValue = buf;
					
					this.clientsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: buf,
						title: ((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
					});
				}
				break;
		}

		this.informationLoaded.clientsLineChart = true;
	}

	initializeTopClients() {
		this.topClientsRows = [];

		for(let check of this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location)) {
			if(this.us._users[check.client] && check.total) {
				if(!this.topClientsRows.find(r => r.client._id == check.client)) {
					this.topClientsRows.push(
						{
							client: this.us._users[check.client],
							client_name: this.us._users[check.client].name,
							appointments: this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).filter(a => a.client == check.client).length,
							revenue: check.total || 0,
							sum_tips: check.tips || 0
						}
					);
				} else {
					this.topClientsRows.find(r => r.client._id == check.client).revenue += check.total || 0;
					this.topClientsRows.find(r => r.client._id == check.client).sum_tips += check.tips || 0;
				}
			}
		}

		this.topClientsRows.sort((a, b) => b.revenue - a.revenue);

		this.informationLoaded.topClients = true;
	}

	initializeNoShowsClients() {
		this.noShowsRows = [];

		for(let appt of this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location)) {
			if(appt.status === 'No-show') {
				if(!this.noShowsRows.find(r => r.client._id == appt.client)) {
					this.noShowsRows.push(
						{
							client: this.us._users[appt.client],
							client_name: this.us._users[appt.client].name,
							no_shows: 1,
							no_shows_value: appt.price || this.ss._services[appt.service].price,
							appointments: this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).filter(a => a.client == appt.client).length,
							revenue: this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location).filter(c => c.client == appt.client).reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)
						}
					);
				} else {
					this.noShowsRows.find(r => r.client._id == appt.client).no_shows ++;
					this.noShowsRows.find(r => r.client._id == appt.client).no_shows_value += appt.price || this.ss._services[appt.service].price;
				}
			}
		}

		this.informationLoaded.noShowsClients = true;
	}
}