import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Injectable, AfterViewInit, ElementRef } from '@angular/core';
import { NoteService, AppointmentService, UserService, ServiceService, LoaderService } from '@services';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { MongoService } from 'wacom';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `First page`;
  itemsPerPageLabel = `Items per page:`;
  lastPageLabel =`Last page`;
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }
}

@Component({
	selector: 'app-service-history',
	templateUrl: './service-history.component.html',
	styleUrls: ['./service-history.component.scss'],
	providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class ServiceHistoryComponent implements OnInit, AfterViewInit {
	@Input() searching: string = '';
	@Input() services: any;
	@Input() date: any;
	public config: any = {
		page: 1,
		perPage: 100
	}
	constructor(public ap: AppointmentService, public us: UserService, public ss: ServiceService, private datePipe: DatePipe, private mongo: MongoService, private loader: LoaderService, private eref: ElementRef) {}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		if(window.innerWidth <= 1024 && window.innerWidth > 767 && this.config.perPage != 50) this.config.perPage = 50;
		if(window.innerWidth <= 767 && this.config.perPage != 25) return this.config.perPage = 25;

		this.mongo.on('user', () => {
			for(let appointment of this.ap.past_arr) {
				appointment.nameAp = this.us._users[appointment.client]?.name;
				appointment.servicesAp = this.ss._services[appointment.service]?.name;
				appointment.date = appointment.day.singleDate?.epoc;
				appointment.time = parseInt(appointment.start.split(':')[0]) * 60 + parseInt(appointment.start.split(':')[1]);
			}
		});
	}
	ngAfterViewInit() {
		this.mongo.on('user appointment service', () => {
			this.loader.remove();
		});
	}
	public sort_type:any = {};
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	timeFormatting(time) {
		var d = new Date();
		d.setHours(time.split(':')[0]);
		d.setMinutes(time.split(':')[1]);
		return this.datePipe.transform(d, 'shortTime');
	}
	handlePageEvent(event) {
		this.config.page = event.pageIndex + 1;
	}
}
