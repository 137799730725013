import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class TimeService {
	public times: any = [];
	public _times: any = {};
	public by_author: any = {};
	public author_by_day: any = {};
	public current:any = [];
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(private mongo: MongoService, private alert: AlertService) { 
		this.times = mongo.get('time', {
			replace: {
				created: function(val, cb, doc){
					let timestamp = doc._id.toString().substring(0,8);
					cb(new Date(parseInt(timestamp,16)*1000));
				},
				end: function(val, cb, doc){
					if(!val) cb(null);
					else cb(new Date(val));
				}
			},
			query: {
				current: function(doc){
					return !doc.end;
				}
			},
			groups: {
				author: {
					field: function(doc){ return doc.author; }
				},
				author_by_day: {
					field: function(doc){
						return doc.author+doc.created.getFullYear()+(doc.created.getMonth()+1)+doc.created.getDate();
					}
				}
			}
		}, (arr, obj) => {
			console.log(obj.author);
			this.current = obj.current;
			this.by_author = obj.author;
			this.author_by_day = obj.author_by_day;
			this._times = obj;
			this.refresh();
		});
	}
	toggle(){
		if(this.current.length){
			this.current[0].end = new Date();
			this.update(this.current[0]);
		}else this.create();
	}
	create(time:any={}) {
		if(time._id) return this.save(time);
		this.mongo.create('time', time, created=>{
			this.alert.show({
				text: 'Time has been added.'
			});
		}); 
	}
	update(time) {
		this.mongo.afterWhile(time, ()=> {
			this.save(time);
		});
	}
	save(time){
		this.mongo.update('time', time, ()=>{
			this.alert.show({
				text: 'Time has been updated.'
			});
		});
	}
	delete(time) {
		this.mongo.delete('time', time, ()=>{
			this.alert.show({
				text: 'Time has been deleted.'
			});
		});
	}
}
