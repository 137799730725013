import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, ModalService, MongoService } from 'wacom';
import { AppointmentService, UserService, ServiceService, CheckoutService, DiscountService, ProductService, TaxService, LoaderService } from '@services';
import * as es6printJS from "print-js";
import { HttpClient } from '@angular/common/http';
import { modalAnimation } from 'src/app/common/animation/animation.component';
import { Router } from '@angular/router';
@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [modalAnimation]
})
export class CheckoutComponent implements OnInit, OnDestroy {
	public noteEditor: any;
	public check: any = {};
	public close;
	public submitted = false;
	public invoice_print = true;
	public add_popup = false;
	public characterLimit: string = null;
    public position: string = 'positionBottom';
	@ViewChild('productRef', { static: true }) productRef: ElementRef;
	@ViewChild('selectDiscountRef', { static: true }) selectDiscountRef: ElementRef;

	constructor(
		public us: UserService, 
		public aps: AppointmentService, 
		public ss: ServiceService,
		public cs: CheckoutService, 
		public alert: AlertService,
		public ds: DiscountService,
		private http: HttpClient,
		public ps: ProductService,
		public ts: TaxService,
		private mongo: MongoService,
		private loader: LoaderService,
		private router: Router,
		public modal: ModalService
	) { }

	ngOnInit(): void {
		this.mongo.on('checkout', () => {
			if (this.cs.appointment[this.check.appointment.id]) {
				this.alert.error({
					text: "The check has already been paid",
					timeout: 0,
					progress: false,
					closable: false
				});
			}
		});
		if (this.check.notes) this.noteEditor = JSON.parse(this.check.notes);
		this.check.invoice_email = true;
		this.check.use_taxes = true;
		this.check.tips = 0;
	}
	ngOnDestroy(): void {
		this.alert.destroy();
	}
	save() {
		if (this.cs.appointment[this.check.appointment.id]) return;
		this.submitted = true;
		if(!this.check.type) return this.alert.error({
			text: "Please fill required fields",
		});
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		if (this.noteEditor) this.check.notes = JSON.stringify(this.noteEditor);
		this.cs.create(this.check, (created)=> {
			for(let product of this.check.products) {
				const index = this.ps.products.findIndex(p => p._id == product.id);
				if(this.ps.products[index].quantity != null && this.ps.products[index].quantity != undefined) this.ps.products[index].quantity -= product.qty;
			}
			if (this.invoice_print) {
				this.http.get('/api/invoice/file/'+created._id, { responseType: 'blob'}).subscribe(res => {
					if(res) es6printJS(URL.createObjectURL(res), 'pdf');
				});
			}
			let app = JSON.parse(JSON.stringify(this.aps._appointments[this.check.appointment.id]));
			app.status = 'Completed';
			this.aps.save(app);
			this.loader.remove();
			this.close();
		}); 
	}
	formattedTime(time: number) {
		return `${Math.trunc(time / 60)}h${time % 60 > 0 ? ` ${time % 60}m` : ''}`;
	}
	get tax() {
		var tax = 0;
		if (this.check.use_taxes) {
			for (let tax_id of this.check.appointment.taxes) {	
				tax += (this.ss._services[this.aps._appointments[this.check.appointment.id].service].price * this.ts._taxes[tax_id].amount) / 100;
			}
			if (this.check.products) this.check.products.forEach(product => {
				let product_tax = 0;
				for (let tax_id of product.taxes) {
					product_tax += (this.ps._products[product.id].price * this.ts._taxes[tax_id].amount) / 100;
				}
				tax += product_tax * product.qty;
			});
		}
		if (this.check.tax != tax) this.check.tax = tax;
		return tax.toFixed(2);
	}
	get total() {
		var total = 0;
		total += this.ss._services[this.aps._appointments[this.check.appointment.id].service].price;
		if (this.check.appointment.discount) {
			if (this.ds._discounts[this.check.appointment.discount]?.kind=='amount') total -= this.ds._discounts[this.check.appointment.discount]?.amount;
			if (this.ds._discounts[this.check.appointment.discount]?.kind=='perc') total -= (this.ss._services[this.aps._appointments[this.check.appointment.id].service].price * this.ds._discounts[this.check.appointment.discount]?.amount) / 100
		}
		if (this.check.products) this.check.products.forEach(product => {total += parseFloat(this.productTotal(product))});
		total += parseFloat(this.check.tax);
		if (this.check.tips) total += parseFloat(this.check.tips);
		if (this.check.total != total) this.check.total = total;
		return total.toFixed(2);
	}
	addProduct(product) {
		this.add_popup = false;
		if(!this.check.products.find(p => p.id == product._id)) {
			this.check.products.push({id: product._id, qty: 1, discount: product.discount, taxes: product.tax || []});
		} else {
			this.check.products.find(p => p.id == product._id).qty += 1;
		}
	}
	productTotal(product: any) {
		var res = this.ps._products[product.id].price * product.qty;
		if (this.ds._discounts[product.discount]?.kind=='amount') res -= this.ds._discounts[product.discount]?.amount;
		if (this.ds._discounts[product.discount]?.kind=='perc') res -= (this.ps._products[product.id].price * product.qty * this.ds._discounts[product.discount]?.amount) / 100
		return res.toFixed(2);
	}
	openProductModal(product) {
		const profile = {...product};
		profile._id = profile.id;
		this.router.navigate([],{ queryParams: { modal: 'open' }});
		this.modal.show({
			class: 'small',
			component: 'modal',
			profile: profile,
			title: 'product',
			components: [
				{
					customRef: this.productRef
				}, {
					type: 'NUMBER',
					input: 'qty',
					label: 'Quantity',
					placeholder: '1',
					min: 1,
					reqText: 'Quantity must be 1 or higher',
					required: true,
				}, {
					customRef: this.selectDiscountRef,
					input: 'discount',
				}, {
					type: 'SELECT',
					input: 'taxes',
					label: 'Tax',
					placeholder: 'No tax',
					required: false,
					items: this.ts.taxes,
					clearable: true,
					searchable: false,
					multiple: true,
					bindValue: "_id",
					bindLabel: "name"
				},
			],
			closeAutomatically: true,
			onDelete: (profile: any) => {
				this.check.products.splice(this.check.products.findIndex(p => p.id == profile.id), 1);
			},
			onSave: (profile: any) => {
				this.check.products[this.check.products.findIndex(p => p.id == profile.id)] = profile;
			}
		});	
	}
	positionChange(event) {
        if(event.connectionPair.originY === 'top') this.position = 'positionTop';
        if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    }
} 
