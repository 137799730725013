<div class="calendar-container" cdkScrollable>
	<!--  ===  Clients Navigation panel with tabs  ===  -->
	<div class="cal-nav">
		<div class="cal-nav__left">
			<!-- info: Calendar -->
			<ng-container *ngIf="calendar">
				<div class="waw-select mat-select _search hidden-mob" *ngIf="loc.locations?.length">
					<app-select 
						placeholder="Office" 
						[(value)]="location"
						[items]="loc.locations" 
						[clearable]="true" 
						[searchable]="false"
						[multiple]="false"
						bindLabel="name" 
						bindValue="_id" 
						icon="search" 
					></app-select>
				</div>
				<div class="waw-select mat-select _staff hidden-mob">
					<app-select 
						placeholder="Staff" 
						[(value)]="selected_staff"
						(valueChange)="aps.refresh()"
						[items]="us.allowed_appointments" 
						[clearable]="true" 
						[searchable]="true"
						[multiple]="true"
						bindLabel="name" 
						bindValue="_id"
						icon="staff" 
					></app-select>
				</div>
				<div class="waw-select mat-select _category hidden-mob">
					<app-select 
						placeholder="Category" 
						[(value)]="selected_category"
						(valueChange)="aps.refresh()"
						[items]="cat.categories" 
						[clearable]="true" 
						[searchable]="true"
						[multiple]="true"
						bindLabel="name" 
						bindValue="_id"
						icon="category" 
					></app-select>
				</div>
				<!-- info: filter only for mobile device -->
				<div class="filter-mobile" (clickOutside)="filterClose($event)">
					<div class="dis-mob fl-c-c nav-filter">
						<button class="btn-icon page-nav__btn" (click)="filterShow=!filterShow;"><span class="material-icons">filter_alt</span></button>
					</div>
					<div class="filter-mobile-overlay" *ngIf="filterShow" (click)="$event.stopPropagation();">
						<div class="waw-datepicker col-1 _arrow">
							<datepicker [date]="model" [readonly]="true" (valueChange)="model=$event"></datepicker>
						</div>
						<div class="waw-select col-1">
							<span class="waw-select__title">Office location</span>
							<app-select 
								placeholder="Office" 
								[(value)]="location"
								[items]="loc.locations" 
								[clearable]="true" 
								[searchable]="false"
								[multiple]="false"
								bindLabel="name" 
								bindValue="_id"
							></app-select>
						</div>
						<div class="waw-select col-1">
							<span class="waw-select__title">Staff</span>
							<app-select 
								placeholder="Staff" 
								[(value)]="selected_staff"
								(valueChange)="aps.refresh()"
								[items]="us.allowed_appointments" 
								[clearable]="true" 
								[searchable]="true"
								[multiple]="true"
								bindLabel="name" 
								bindValue="_id"
							></app-select>
						</div>
						<div class="waw-select col-1">
							<span class="waw-select__title">Category</span>
							<app-select 
								placeholder="Category" 
								[(value)]="selected_category"
								(valueChange)="aps.refresh()"
								[items]="cat.categories" 
								[clearable]="true" 
								[searchable]="true"
								[multiple]="true"
								bindLabel="name" 
								bindValue="_id"
							></app-select>
						</div>
					</div>
				</div>
			</ng-container>
		</div>


		<div class="cal-nav__right">

			<div class="cal-nav__btn fl-sb-c">
				<button class="btn-icon page-nav__btn ml15" (click)="edit_lunch({});" *ngIf="us.role"><span class="icon-clock"></span></button>
				<button class="add-appoint-lg waw-btn _primary fl-c-c ml15" (click)="edit_appointment({status: 'New'});">Add appointment</button>
			</div>
		</div>
	</div>

	<div class="holiday-title" *ngIf="isClosed()">
		<span>{{holiday.type}}</span>
		<hr>
		<span>{{holiday.name}}</span>
	</div>

	<button class="btn-icon page-nav__btn ml15 btn-scroll" (click)="calendarScrollLock=!calendarScrollLock" [class._active]="calendarScrollLock"><span class="material-icons">control_camera</span></button>
	<button class="btn-icon page-nav__btn ml15 filter-sm" (click)="filterShow=!filterShow"><span class="material-icons">filter_alt</span></button>
	<button class="btn-icon page-nav__btn ml15 all-hours-sm" (click)="allDay = !allDay"><span class="material-icons">access_time</span></button>
	<button class="btn-icon page-nav__btn ml15 add-lunch-sm" (click)="edit_lunch({});"><span class="material-icons">lunch_dining</span></button>
	<button class="add-appoint-sm btn-icon page-nav__btn" (click)="edit_appointment({status: 'New'});">
		<span class="material-icons">add</span>
	</button>
	<div class="calendar-wrapper" [class._scroll]="calendarScrollLock">
		<div class="cal-date">
			<div class="info-tooltip">* For horizontal scroll use "SHIFT + Mousewheel"</div>
			<div class="col-date__currentDate" style="padding-left: 24px;">
				<div style="position: absolute;left: 0px;font-size: 19px;">
					<span (click)="previousDay()"> <span style="font-size: 16px;cursor: pointer;" class="material-icons">chevron_left</span> </span>
					<span style="margin-left: 88px;" (click)="nextDay()"><span style=" font-size: 16px;cursor: pointer;" class="material-icons">chevron_right</span> </span>
				</div>
				<span class="cal-date-small" (click)="openPicker = true">{{model.getValue().singleDate.jsDate|date:'EEEE'}}</span>
				<div (click)="openPicker = true">{{model.getValue().singleDate.jsDate|date:'M/d/yyyy'}}</div>
				<div class="cal-date-allhours" style="margin-left: -20px" (click)="allDay = !allDay">Show all hours</div>
				<datepicker type="calendar" [date]="model.getValue()" [open]="openPicker" (close)="openPicker = false" (valueChange)="model.next($event)"></datepicker>
				<!-- <mat-form-field class="example-full-width _left "   appearance="fill" >
					<input matInput [matDatepicker]="picker"  placeholder="Choose a date" name="mydate"  [(ngModel)]="model" (focus)="render()" (blur)="render()" [hidden]="true">
					<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker [dateClass]="dateClass"></mat-datepicker>
				</mat-form-field> -->
				<!-- <span class="icon icon-chevron _left" (click)="prev()"></span>
					<span class="icon icon-chevron _right" (click)="next()"></span> -->
			</div>
			<div class="col-date__body">
				<div class="cal-date__item" *ngFor="let time of times|hours:location:model.getValue().singleDate.jsDate:allDay:now; index as i;">
					<!-- <span class="cal-date__item--line _main"></span> -->
					<span [class.hover]='row==i*4+1' class="row_container" (mouseover)="row_selected(i, 1)" (click)="create_appointment(time.getTime())" [class.disabled-staff]="isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()|date:'HH:mm')||isClosed()">
						<span class="cal-date__item--time"><span class="timeLg">{{time|date:'h:mm a'}} </span><span class="timeSm">{{time|date:'h a'}}</span></span>
					</span>
					<span [class.hover]='row==i*4+2' class="row_container _20p" (mouseover)="row_selected(i, 2)" (click)="create_appointment(time.getTime()+900000)" [class.disabled-staff]="isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+900000|date:'HH:mm')||isClosed()">
						<span class="cal-date__item--line">
							<span class="col-date__item--lineHover" *ngIf="row==i*4+2">{{time.getTime()+900000|date:'h:mm a'}}</span>
						</span>
					</span>
					<span [class.hover]='row==i*4+3' class="row_container _40p" (mouseover)="row_selected(i, 3)" (click)="create_appointment(time.getTime()+1800000)" [class.disabled-staff]="isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+1800000|date:'HH:mm')||isClosed()">
						<span class="cal-date__item--line">
							<span class="col-date__item--lineHover" *ngIf="row==i*4+3">{{time.getTime()+1800000|date:'h:mm a'}}</span>
						</span>
					</span>
					<span [class.hover]='row==i*4+4' class="row_container _60p" (mouseover)="row_selected(i, 4)" (click)="create_appointment(time.getTime()+2700000)" [class.disabled-staff]="isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+2700000|date:'HH:mm')||isClosed()">
						<span class="cal-date__item--line">
							<span class="col-date__item--lineHover" *ngIf="row==i*4+4">{{time.getTime()+2700000|date:'h:mm a'}}</span>
						</span>
					</span>
				</div>
			</div>
		</div>
		<div class="cal-table">
			<div class="cal-table__row pos-rel" cdkScrollable mwlDraggableScrollContainer cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="us.allowed_appointments | staffById: selected_staff: selected_category: location: aps.now" (cdkDropListDropped)="drop($event)">
				<ng-container *ngIf="calendar">
					<!-- info: Calendar -->
					<div class="cal-table__item" *ngFor="let user of us.allowed_appointments | staffById: selected_staff: selected_category: location: aps.now; index as i;" [class.active]="popupOpened == i" cdkDrag [cdkDragData]="user" [cdkDragStartDelay]="100">
						<div class="cal-date__item bd-n" *ngFor="let time of times|hours:location:model.getValue().singleDate.jsDate:allDay:now; index as j;" [class.disabled-staff]="isVacationStaff(user, model.getValue().singleDate.jsDate|date:'EEEE', model.getValue().singleDate.jsDate|date:'M/d/yyyy')">
							<span class="row_container" (mouseover)="row_selected(j, 1)" (click)="create_appointment(time.getTime(), user)" [class.disabled-staff]="isWorkingStaff(user, time.getTime()|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')||isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()|date:'HH:mm')||isClosed()"></span>
							<span class="row_container _20p" (mouseover)="row_selected(j, 2)" (click)="create_appointment(time.getTime()+900000, user)" [class.disabled-staff]="isWorkingStaff(user, time.getTime()+900000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')||isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+900000|date:'HH:mm')||isClosed()"></span>
							<span class="row_container _40p" (mouseover)="row_selected(j, 3)" (click)="create_appointment(time.getTime()+1800000, user)" [class.disabled-staff]="isWorkingStaff(user, time.getTime()+1800000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')||isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+1800000|date:'HH:mm')||isClosed()"></span>
							<span class="row_container _60p" (mouseover)="row_selected(j, 4)" (click)="create_appointment(time.getTime()+2700000, user)" [class.disabled-staff]="isWorkingStaff(user, time.getTime()+2700000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')||isOffice(model.getValue().singleDate.jsDate|date:'EEEE', time.getTime()+2700000|date:'HH:mm')||isClosed()"></span>
						</div>
						<div class="cal-content">
							<div>
								<div class="cal-table__user" cdkDragHandle (click)="set_staff_info(user._id);" (clickOutside)="staff_info=null" cdkOverlayOrigin #staff="cdkOverlayOrigin">
									<div class="cal-table__avatar" [class._letter]="!user.avatarUrl">
										<picture [user]="user"></picture>
									</div>
									<div class="cal-table__name">{{user.name}}</div>
								</div>
								<ng-template
									cdkConnectedOverlay
									[cdkConnectedOverlayOrigin]="staff"
									[cdkConnectedOverlayOpen]="staff_info==user._id"
									[cdkConnectedOverlayFlexibleDimensions]="false"
									(detach)="staff_info=null"
								>
									<div class="staff-popup" [@flyInOut]="staff_info==!user._id" *ngIf="staff_info==user._id" [ngStyle]="{'display': vw >= 768 ? 'none' : 'block'}">
										<div class="popup-block">
											<div class="popup-block__title font-bold">{{user.name}}</div>
											<div class="popup-block__link" (click)="editStaff(user)">
												<span>Staff Profile</span>
											</div>
											<div class="popup-block__link" (click)="weekView()">
												<span>Week View</span>
											</div>
										</div>
										<div class="popup-block">
											<div class="popup-block__title"><span>Schedule</span></div>
											<a class="popup-block__link" (click)="edit_lunch({user: user._id})" *ngIf="calendar">
												<span>Add Lunch</span>
											</a>
											<a class="popup-block__link" (click)="editWorkingDays(user)"><span>Working Hours</span></a>
											<a class="popup-block__link" (click)="editWorkingHours(user)"><span>Edit this day</span></a>
										</div>
									</div>
								</ng-template>
								<div class="staff-popup" [@flyInOut]="staff_info==!user._id" *ngIf="staff_info==user._id" [ngStyle]="{'display': vw < 768 ? 'none' : 'block'}">
									<div class="popup-block">
										<div class="popup-block__title font-bold">{{user.name}}</div>
										<div class="popup-block__link" (click)="editStaff(user)">
											<span>Staff Profile</span>
										</div>
										<div class="popup-block__link" (click)="weekView()">
											<span>Week View</span>
										</div>
									</div>
									<div class="popup-block">
										<div class="popup-block__title"><span>Schedule</span></div>
										<a class="popup-block__link" (click)="edit_lunch({user: user._id})" *ngIf="calendar">
											<span>Add Lunch</span>
										</a>
										<a class="popup-block__link" (click)="editWorkingDays(user)"><span>Working Hours</span></a>
										<a class="popup-block__link" (click)="editWorkingHours(user)"><span>Edit this day</span></a>
									</div>
								</div>
							</div>
							<div class="pos-rel">
								<div class="cal-table__item__body" [ngStyle]="{top: top(appointment.top)+'px'}" *ngFor="let appointment of aps._appointments.calendar[user._id+model.getValue().singleDate.formatted]|appointments:location:aps.now" mwlDraggable (ghostElementCreated)="prepare_add_row($event)" (dragEnd)="update_appointment(appointment, $event, i)" [dropData]="appointment" (mouseover)="card_hover(appointment.top, appointment.height)" (clickOutside)="appointment.selected = false; appointment.showPopup = false; popupOpened = null;" (dragStart)="appointment.selected = false; appointment.showPopup = false;" [touchStartLongPress]="config" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
									<!--  [dragAxis]="{y:true}" -->
									<div class="cal-appoint" [class.appoint-min]="appointment.height < 58" [class.appoint-medium]="appointment.height < 80" [ngStyle]="{height: appointment.height+'px'}" (click)="popup(appointment, i)" (dblclick)="open(appointment)">
										<span class="cal-appoint__color" [style.background]="ss._services[appointment.service]?.color"></span>
										<div class="fl-sb-c">
											<div class="cal-appoint__title">{{ss._services[appointment.service]?.name}}</div>
											<div *ngIf="appointment.height < 58" class="cal-appoint__status-min" [ngClass]="appointment.status">{{appointment.status}}</div>
											<span *ngIf="appointment.changedStatusManually" class="material-icons changedStatusManually">auto_fix_high</span>
											<span *ngIf="appointment.height < 58" class="cal-appoint__status-icon material-icons" [ngClass]="appointment.status">circle</span>
										</div>
										<div class="cal-appoint__time">{{appointment.start}}-{{appointment.end}}</div>
										<div class="fl-sb-c flex-wrap">
											<div class="cal-appoint__staff" [class._deleted]="us._users[appointment.client]?.deleted">{{us._users[appointment.client]?.name}}</div>
											<div class="cal-appoint__status" [ngClass]="appointment.status">{{appointment.status}}</div>
										</div>
										<div *ngIf="appointment.lockStaff" class="lockStaff-name">
											{{us._users[appointment.user].name}} *
										</div>
										<ng-template
											cdkConnectedOverlay
											[cdkConnectedOverlayOrigin]="trigger"
											[cdkConnectedOverlayOpen]="appointment.selected"
											[cdkConnectedOverlayFlexibleDimensions]="true"
											[cdkConnectedOverlayScrollStrategy]="scrollStrategy"
											(detach)="appointment.selected=false"
										>
											<appointment-popup [appointment]="appointment" [model]="model.getValue()"></appointment-popup>
										</ng-template>
									</div>
								</div>
							</div>
							<div class="pos-rel">
								<div class="cal-table__lunch__body" [ngStyle]="{top: top(lunch.top)+'px'}" *ngFor="let lunch of ls._lunchs.calendar[user._id+model.getValue().singleDate.formatted]" mwlDraggable (ghostElementCreated)="prepare_add_row($event)" (dragEnd)="update_lunch(lunch, $event, i)" [dropData]="lunch" [touchStartLongPress]="config" (mouseover)="card_hover(lunch.top, lunch.height)">
									<!--  [dragAxis]="{y:true}" -->
									<div class="col-lunch" [class.lunch-min]="lunch.height < 30" [ngStyle]="{height: lunch.height+'px'}" (click)="edit_lunch(lunch);">Lunch</div>
								</div>
							</div>
						</div>
					</div>

				</ng-container>
				<ng-container *ngIf="!calendar">
					<!-- info: Schedule -->
					<div class="cal-table__item" *ngFor="let date of [0,1,2,3,4,5,6]; index as i;" [class.active]="popupOpened == i">
						<div class="cal-date__item bd-n" *ngFor="let time of times|hours:location:model.getValue().singleDate.jsDate:allDay:now; index as j;" [class.disabled-staff]="isVacationStaff(us._users[us._id], model.getValue().singleDate.jsDate|date:'EEEE', model.getValue().singleDate.jsDate|date:'M/d/yyyy')">
							<span class="row_container" (mouseover)="row_selected(j, 1)" (click)="create_appointment(time.getTime(), us._users[us._id])" [class.disabled-staff]="isWorkingStaff(us._users[us._id], time.getTime()|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')"></span>
							<span class="row_container _20p" (mouseover)="row_selected(j, 2)" (click)="create_appointment(time.getTime()+900000, us._users[us._id])" [class.disabled-staff]="isWorkingStaff(us._users[us._id], time.getTime()+900000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')"></span>
							<span class="row_container _40p" (mouseover)="row_selected(j, 3)" (click)="create_appointment(time.getTime()+1800000, us._users[us._id])" [class.disabled-staff]="isWorkingStaff(us._users[us._id], time.getTime()+1800000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')"></span>
							<span class="row_container _60p" (mouseover)="row_selected(j, 4)" (click)="create_appointment(time.getTime()+2700000, us._users[us._id])" [class.disabled-staff]="isWorkingStaff(us._users[us._id], time.getTime()+2700000|date:'HH:mm', model.getValue().singleDate.jsDate|date: 'M/d/yyyy', model.getValue().singleDate.jsDate|date: 'EEEE')"></span>
						</div>
						<div class="cal-content">
							<div>
								<div class="cal-table__user schedule-date">
									<div>{{(model.getValue().singleDate.jsDate.getTime()+(i*86400000))|date:"EEEE"}}</div>
									<div>{{(model.getValue().singleDate.jsDate.getTime()+(i*86400000))|date}}</div>
								</div>
							</div>
							<div class="pos-rel" style="z-index: 3;">
								<div class="cal-table__item__body" [ngStyle]="{top: top(appointment.top)+'px'}" *ngFor="let appointment of aps._appointments.calendar[us._id+((model.getValue().singleDate.jsDate.getTime()+(i*86400000))|date:'M/d/yyyy')]|appointments:location:aps.now" mwlDraggable (ghostElementCreated)="prepare_add_row($event)" (dragEnd)="update_appointment(appointment, $event)" [dropData]="appointment" (mouseover)="card_hover(appointment.top, appointment.height)" (dragStart)="appointment.selected = false; appointment.showPopup = false;" [touchStartLongPress]="config" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
									<!--  [dragAxis]="{y:true}" ^4.5.3 -->
									<div class="cal-appoint" [class.appoint-min]="appointment.height < 58" [class.appoint-medium]="appointment.height < 80" [ngStyle]="{height: appointment.height+'px'}" (click)="popup(appointment, i)" (dblclick)="open(appointment)">
										<div class="appoint-disabled-click _active"></div>
										<span class="cal-appoint__color" [style.background]="ss._services[appointment.service]?.color"></span>
										<div class="fl-sb-c">
											<div class="cal-appoint__title">{{ss._services[appointment.service]?.name}}</div>
											<div *ngIf="appointment.height < 58" class="cal-appoint__status-min" [ngClass]="appointment.status">{{appointment.status}}</div>
											<span *ngIf="appointment.changedStatusManually" class="material-icons changedStatusManually">auto_fix_high</span>
											<span *ngIf="appointment.height < 58" class="cal-appoint__status-icon material-icons" [ngClass]="appointment.status">circle</span>
										</div>
										<div class="cal-appoint__time">{{appointment.start}}-{{appointment.end}}</div>
										<div class="fl-sb-c flex-wrap">
											<div class="cal-appoint__staff">{{us._users[appointment.client]?.name}}</div>
											<div class="cal-appoint__status" [ngClass]="appointment.status">{{appointment.status}}</div>
										</div>
										<div *ngIf="appointment.lockStaff" class="lockStaff-name">
											{{us._users[appointment.user].name}} *
										</div>
									</div>
									<ng-template
										cdkConnectedOverlay
										[cdkConnectedOverlayOrigin]="trigger"
										[cdkConnectedOverlayOpen]="appointment.selected"
										[cdkConnectedOverlayFlexibleDimensions]="true"
										[cdkConnectedOverlayScrollStrategy]="scrollStrategy"
										(detach)="appointment.selected=false"
									>
										<appointment-popup [appointment]="appointment" [model]="model.getValue()"></appointment-popup>
									</ng-template>
								</div>
							</div>
							<div class="pos-rel" *ngIf="us.role">
								<div class="cal-table__lunch__body" [ngStyle]="{top: top(lunch.top)+'px'}" *ngFor="let lunch of ls._lunchs.calendar[us._id+((model.getValue().singleDate.jsDate.getTime()+(i*86400000))|date:'M/d/yyyy')]" mwlDraggable (ghostElementCreated)="prepare_add_row($event)" (dragEnd)="update_lunch(lunch, $event, i)" [dropData]="lunch" [touchStartLongPress]="config" (mouseover)="card_hover(lunch.top, lunch.height)">
									<!--  [dragAxis]="{y:true}" -->
									<div class="col-lunch" [class.lunch-min]="lunch.height < 30" [ngStyle]="{height: lunch.height+'px'}" (click)="edit_lunch(lunch);">Lunch</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
				<div class="flex-grow" style="flex-grow: 1;margin-top: 80px;">
					<div class="cal-date__item" *ngFor="let time of times|hours:location:model.getValue().singleDate.jsDate:allDay:now; index as i;">
						<!-- <span class="cal-date__item--line _main"></span> -->
						<span [class.hover]='row==i*4+1' class="row_container" (mouseover)="row_selected(i, 1)" (click)="create_appointment(time.getTime())">
							<span class="cal-date__item--time"></span>
						</span>
						<span [class.hover]='row==i*4+2' class="row_container _20p" (mouseover)="row_selected(i, 2)" (click)="create_appointment(time.getTime()+900000)">
							<span class="cal-date__item--line">
								<span class="col-date__item--lineHover" *ngIf="row==i*4+2"></span>
							</span>
						</span>
						<span [class.hover]='row==i*4+3' class="row_container _40p" (mouseover)="row_selected(i, 3)" (click)="create_appointment(time.getTime()+1800000)">
							<span class="cal-date__item--line">
								<span class="col-date__item--lineHover" *ngIf="row==i*4+3"></span>
							</span>
						</span>
						<span [class.hover]='row==i*4+4' class="row_container _60p" (mouseover)="row_selected(i, 4)" (click)="create_appointment(time.getTime()+2700000)">
							<span class="cal-date__item--line">
								<span class="col-date__item--lineHover" *ngIf="row==i*4+4"></span>
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
