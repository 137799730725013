import { Injectable } from '@angular/core';
import { MongoService, AlertService, HttpService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class LunchService {
	public lunchs: any = [];
	public _lunchs: any = {
		calendar: {}
	};
	public loaded: boolean = false;
	timeToPixel(time){
		return time / 3600000 * 80;
	}
	timeToRange(time){
		time = time / 60000;
		let h = Math.floor(time / 60);
		let m = Math.floor(time % 60);
		return (h<10&&'0'||'')+h+':'+(m<10&&'0'||'')+m;
	}
	rangeToTime(start, end){
		if(!start||!end) return 0;
		start = start.split(':');
		start[0] = Number(start[0]);
		start[1] = Number(start[1]);
		end = end.split(':');
		end[0] = Number(end[0]);
		end[1] = Number(end[1]);
		if(end[1]<start[1]){
			end[0]--;
			end[1]+=60;
		}
		if(end[0]<start[0]) return 0;
		return ( (end[0]-start[0])*60 + end[1]-start[1] ) * 60000;
	}
	private replace = {
		end: (val, cb, doc)=>{
			if(doc.duration && doc.start){
				cb(this.timeToRange(this.rangeToTime('00:00', doc.start)+(doc.duration*60000)));
			}else cb(val);
		},
		top: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime('00:00', doc.start)));
		},
		height: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime(doc.start, doc.end)));
		}
	};
	constructor(private alert: AlertService, private mongo: MongoService, private http: HttpService) {}
	create(lunch, cb: any = () => {}) {
		if(lunch._id) return this.update(lunch, cb);
		this.replace.end(lunch.end, (end)=>{
			lunch.end = end;
		}, lunch);
		this.mongo.create('lunch', lunch, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Lunch has been created.'
			});
		}); 
	}
	update(lunch, cb: any = () => {}) {
		this.mongo.afterWhile(lunch, ()=> {
			this.save(lunch, cb);
		});
	}
	save(lunch, cb: any = () => {}) {
		this.replace.end(lunch.end, end=>{
			lunch.end = end;
		}, lunch);
		this.replace.top(lunch.top, top=>{
			this._lunchs[lunch._id].top = top;
			lunch.top = top;
		}, lunch);
		this.replace.height(lunch.height, height=>{
			this._lunchs[lunch._id].height = height;
			lunch.height = height;
		}, lunch);
		this.mongo.update('lunch', lunch, {
			replace: this.replace
		}, (updated) => {
			if(typeof cb === 'function') cb(updated);
		});
	}
	delete(lunch, cb: any = () => {}) {
		this.mongo.delete('lunch', lunch, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
	get(dates_formatted: string[], user = null, cb: any = () => {}) {
		this.loaded = false;
		this.http.post('/api/lunch/get', { 
			dates: dates_formatted, 
			user: user,
		}, (resp)=> {
			if(resp === false) return cb(false);

			const { arr, obj } = this.mongo.set('lunch', {
				replace: this.replace,
				groups: {
					calendar: {
						field: function(doc, cb){
							if(doc.day){
								cb(doc.user+doc.day.singleDate.formatted);
							}
						}
					}
				}
			}, resp);

			this.lunchs = arr;
			this._lunchs = obj;

			this.loaded = true;
			
			if(typeof cb == 'function') cb(resp);
		});	
	}
}
