<div class="modal-box">
	<div class="modal-title">Time Tracking</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<div class="row">
		<div class="waw-select col-1">
			<span class="waw-select__title">Select staff</span>
			<app-select 
				placeholder="Staff" 
				[(value)]="selected_staff"
				[items]="us.staff" 
				[clearable]="false" 
				[searchable]="true"
				bindLabel="name" 
				bindValue="_id"
				class="_zero_padding"
			>
				<ng-template label let-item>
					<div class="select-option-user">
						<span class="select-option-user__img">
							<picture [user]="item"></picture>
						</span>
						<span class="select-option-user__name">{{item.name}}</span>
					</div>
				</ng-template>
				<ng-template option let-item>
					<div class="select-option-user" style="margin: 0px;">
						<span class="select-option-user__img">
							<picture [user]="item"></picture>
						</span>
						<span class="select-option-user__name">{{item.name}}</span>
					</div>
				</ng-template>
			</app-select>
		</div>
		<!-- <div class="col-1">
			<div class="waw-datepicker _arrow">
				<span class="waw-datepicker__title">Day</span>
				<mat-form-field>
					<mat-date-range-input [attr.data-days]="selected_date?.start && selected_date?.end && '('+dates?.length+' days)'" cdkOverlayOrigin #rangePicker="cdkOverlayOrigin" [class._active]="opened" [dateFilter]="rangeFilter">
						<input #dateRangeStart matStartDate [value]="selected_date?.start" (dateChange)="selected_date.start = $event.value" placeholder="Start">
						<input #dateRangeEnd size="12" matEndDate [value]="selected_date?.end" (dateChange)="selected_date.end = $event.value" placeholder="End">
					</mat-date-range-input>
					<button mat-icon-button color="primary" type="button" class="btn" (click)="opened = true; $event.stopPropagation()">
						<span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': opened ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
					</button>
				</mat-form-field>
			</div>
			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="rangePicker"
				[cdkConnectedOverlayOpen]="opened"
				[cdkConnectedOverlayHasBackdrop]="true"
				[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
				[cdkConnectedOverlayPanelClass]="'panelClass'"
				[cdkConnectedOverlayFlexibleDimensions]="false"
				[cdkConnectedOverlayMinHeight]="340"
				(backdropClick)="opened=false;"
				(positionChange)="positionChange($event)"
				(detach)="opened=false; position = 'positionBottom'"
			>
				<div class="picker" [@modal]="us.mobile ? (opened ? 'zoom' : opened) : (opened ? position : opened)">
					<div class="picker__calendar">
						<mat-calendar ></mat-calendar>
					</div>
				</div>
			</ng-template>
		</div> -->
		<div class="col-1">
			<div class="waw-datepicker _arrow">
				<span class="waw-datepicker__title">Day</span>
				<mat-form-field>
					<mat-date-range-input [attr.data-days]="selected_date?.start && selected_date?.end && '('+dates?.length+' days)'" [rangePicker]="picker" [class._active]="picker.opened" [dateFilter]="rangeFilter"	>
						<input #dateRangeStart matStartDate [value]="selected_date?.start" (dateChange)="selected_date.start = $event.value" placeholder="Start">
						<input #dateRangeEnd size="12" matEndDate [value]="selected_date?.end" (dateChange)="selected_date.end = $event.value" placeholder="End">
					</mat-date-range-input>
					<button mat-icon-button color="primary" type="button" class="btn" (click)="picker.open(); $event.stopPropagation()">
						<span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': picker.opened ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
					</button>
					<mat-date-range-picker #picker [touchUi]="us.mobile"></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>

		<!-- <div class="col-1">
			<div class="waw-datepicker _arrow">
				<span class="waw-datepicker__title">Day</span>
				<datepicker [icon]="true"></datepicker>
			</div>
		</div> -->
	</div>
	<h2 class="modal-h2 mb20">Working hours</h2>
	<div class="workhours-list">
		<ng-container *ngIf="dates?.length && selected_staff">
			<div *ngFor="let date of dates" class="workhours-list__item">
				<span class="workhours-list__item__day">{{date | date: 'EE'}}</span>
				<div class="workhours-list__item__block" [tooltip]="ts.now >= date ? ( ts.by_author[selected_staff]|time:date:(date+86400000):ts.now ) : '' " tooltipScrollContainer=".modal">
					<span class="workhours-list__item__block__date text-elipsis">{{date | date: 'd MMM'}}</span>
					<span class="workhours-list__item__block__time text-elipsis">{{ ts.now >= date ? ( ts.by_author[selected_staff]|time:date:(date+86400000):ts.now:2 ) : '' }}</span>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!dates?.length || !selected_staff">
			<div *ngFor="let day of days" class="workhours-list__item">
				<span class="workhours-list__item__day">{{day}}</span>
				<div class="workhours-list__item__block"></div>
			</div>
		</ng-container>
	</div>
	<!-- <div class="row" *ngIf="selected_date&&selected_staff&&ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day]?.length">
		<div class="col-4"><span class="text-gray">Start:</span> {{ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day][0].created|date:'shortTime'}}</div>
		<div class="col-4" *ngIf="ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day][ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day].length-1].end"><span class="text-gray">End: </span> {{ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day][ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day].length-1].end|date:'shortTime'}}</div>
		<div class="col-4"><span class="text-gray">Duration:</span> {{ts.by_author[selected_staff]|time:selected_date?.singleDate.jsDate.getTime():(selected_date?.singleDate.jsDate.getTime()+86400000):ts.now}}</div>
		<div class="col-4"><span class="text-gray">Break:</span> 30 min</div>
	</div> -->
	<!-- <div class="row" *ngIf="selected_date&&selected_staff&&!ts.author_by_day[selected_staff+selected_date?.singleDate.date.year+selected_date?.singleDate.date.month+selected_date?.singleDate.date.day]?.length">Nothing Tracked</div> -->
</div>
