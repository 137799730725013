<div class="containerBox">
    <div class="profile">
        <div class="profile-bar">
            <div class="profile-bar__created">
                <div class="profile-bar__created__title">Created at</div>
                <div class="profile-bar__created__date">{{us.user?._id|mongodate|date:'M/d/yyyy, h:mm a'}}</div>
                <div class="profile-bar__created__author" *ngIf="us.user?.author">by {{us._users[us.user?.author]?.name}}</div>
            </div>
            <div class="profile-bar__more" (click)="profileBarPopup=!profileBarPopup" (clickOutside)="profileBarPopup = false">
                <span></span>
                <span></span>
                <span></span>
                <div [class._active]="profileBarPopup" class="profile-bar__more__popup">
                    <div class="popup__link" *ngIf="us.is.admin||us.is.owner" (click)="edit_client(us.user);">Edit</div>
                    <div class="popup__link" (click)="edit_alert(als.by_client[us.user?._id]&&als.by_client[us.user?._id][0]||{});">Alert</div>
                    <a class="popup__link _danger" *ngIf="us.is.admin||us.is.owner" (click)="deleteClient();">Delete</a>
                </div>
            </div>
            <label class="profile-bar__photo modal-img" [class._letter]="!us.user?.avatarUrl" >
                <picture class="_fz-46" *ngIf="us.user" [user]="us.user" [big]="true"></picture>
                <!-- <span class="profile-bar__photo__label">New client</span> -->
                <label class="img-upload"for="updateUserAvatar"><span class="icon-pencil"></span></label>
                <input type="file" id="updateUserAvatar" (change)="us.addAvatarAdmin($event, us.user);" [hidden]="true" accept="image/*" />
            </label>
            <div class="profile-bar__name">{{us.user?.name}}</div>
            <div class="profile-bar__newEvent"><button (click)="edit_appointment({status: 'New', client: us.user._id})" class="waw-btn">Add Appointment</button></div>
            <div class="profile-alert" *ngFor="let alert of als.by_client[us.user?._id]" (click)="edit_alert(alert)">
                <div><span class="icon-click-info"></span></div>
                <div class="profile-alert__text" [innerHtml]="alert.description | note"></div>
            </div>
            <div class="profile-bar__info">
                <div class="profile-bar__info__item">
                    <div class="profile-bar__info__header">ID user</div>
                    <div class="profile-bar__info__text">{{us.user?.inc}}</div>
                </div>
                <div class="profile-bar__info__item">
                    <div class="profile-bar__info__header">Address</div>
                    <div class="profile-bar__info__text tooltip">
                        <span class="t-hide">{{us.user?.data.address}}</span>
                        <span class="tooltiptext">{{us.user?.data.address}}</span>
                    </div>
                </div>
                <div class="profile-bar__info__item">
                    <div class="profile-bar__info__header">Email</div>
                    <div class="profile-bar__info__text break-word tooltip">
                        <span class="t-hide">{{us.user?.email}}</span>
                        <span class="tooltiptext">{{us.user?.email}}</span>
                    </div>
                </div>
                <div class="profile-bar__info__item">
                    <div class="profile-bar__info__header">Phone</div>
                    <div class="profile-bar__info__text">{{us.user?.data.phone}}</div>
                </div>
    
                <div class="profile-bar__info__item" *ngIf="us.user?.data.additionalPhone">
                    <div class="profile-bar__info__header">Additional phone</div>
                    <div class="profile-bar__info__text">{{us.user?.data.additionalPhone}}</div>
                </div>
    
                <div class="profile-bar__info__item" *ngIf='us.user?.data.gender'>
                    <div class="profile-bar__info__header">Gender</div>
                    <div class="profile-bar__info__text">{{us.user?.data.gender?.label}}</div>
                </div>
    
                <div class="profile-bar__info__item" *ngIf="us.user?.data.birthday">
                    <div class="profile-bar__info__header">Date of birth</div>
                    <div class="profile-bar__info__text">{{us.user?.data.birthday?.singleDate.formatted||''}}</div>
                </div>
    
                <!-- <div class="profile-bar__info__item" *ngIf="us.user?.data.note">
                    <div class="profile-bar__info__header">Notes</div>
                    <div class="profile-bar__info__text" [innerHtml]="us.user?.data.note | note"></div>
                </div> -->
            </div>
        </div>
        <div class="profile-container">
            <div class="profile-main" *ngIf="link==0">
                <div class="profile-topsection">
                    <div class="sales">
                        <div class="sales-top">
                            <div class="sales__total">
                                <div class="sales__number">$ {{aps.all_by_client[us.user?._id]|priceByStatus: 'Completed'}}</div>
                                <div class="sales__title">Total Sales</div>
                            </div>
                            <div class="sales__outstanding">
                                <div class="sales__number">$ {{(aps.all_by_client[us.user?._id]|priceByStatus: 'Canceled')?.toFixed(2)}}</div>
                                <div class="sales__title">Outstanding</div>
                            </div>
                        </div>
                        <div class="sales-bottom">
                            <div class="sales__bookings" (click)="allModal()">
                                <div class="sales__number">{{aps.all_by_client[us.user?._id]?.length||0}}</div>
                                <div class="sales__title">All bookings</div>
                            </div>
                            <div class="sales__completes" (click)="completedModal()">
                                <div class="sales__number">{{aps.all_by_client[us.user?._id]|priceByStatus: 'Completed':true}}</div>
                                <div class="sales__title">Completed</div>
                            </div>
                            <div class="sales__cancelled" (click)="canceledModal()">
                                <div class="sales__number">{{aps.all_by_client[us.user?._id]|priceByStatus: 'Canceled':true}}</div>
                                <div class="sales__title">Canceled</div>
                            </div>
                        </div>
                    </div>
                    <div class="link">
                        <div class="link-item" (click)="link=1; hash.set('files', 'root')">
                            <div class="link-item__title">files</div>
                            <div class="link-item__icon"><span class="icon-chevron"></span></div>
                        </div>
                        <div class="link-item" (click)="link=2; hash.set('notes')">
                            <div class="link-item__title">Notes</div>
                            <div class="link-item__icon"><span class="icon-chevron"></span></div>
                        </div>
                        <div class="link-item" (click)="link=3; hash.set('questionnaire')">
                            <div class="link-item__title">Questionnaire</div>
                            <div class="link-item__icon"><span class="icon-chevron"></span></div>
                        </div>
                    </div>
                </div>
                <div class="profile-event">
                    <div class="tab">
                        <div [class._active]="tab==1" (click)="tab=1;" class="tab__item">appointments</div>
                        <!-- <div [class._active]="tab==2" (click)="tab=2;" class="tab__item">products</div> -->
                        <!-- <div [class._active]="tab==3" (click)="tab=3;" class="tab__item">invoices</div> -->
                        <div [class._active]="tab==4" (click)="tab=4;" class="tab__item">mails</div>
                        <!-- tab for mobile devices -->
                        <div [class._active]="tab==1" (click)="tab=1;" class="tab__item--icon"><span class="material-icons">event</span></div>
                        <!-- <div [class._active]="tab==2" (click)="tab=2;" class="tab__item--icon"><span class="icon-clipboard"></span></div>
                        <div [class._active]="tab==3" (click)="tab=3;" class="tab__item--icon"><span class="icon-file"></span></div> -->
                        <div [class._active]="tab==4" (click)="tab=4;" class="tab__item--icon"><span class="material-icons">email</span></div>
                    </div>
                    <div *ngIf="tab==1" class="profile-appoint">
                        <div class="filter ai-c">
                            <div class="filter-select hidden-mob">
                                <app-select 
                                    placeholder="Sort" 
                                    [(value)]="direction"
                                    (valueChange)="aps.refresh()"
                                    [items]="selectDirection" 
                                    [clearable]="false" 
                                    [searchable]="false"
                                    bindLabel="name" 
                                    bindValue="_id"
                                ></app-select>
                            </div>
                            <div class="waw-datepicker filter-select hidden-mob _arrow">
                                <datepicker [date]="date" (valueChange)="date=$event; aps.refresh();"></datepicker>
                            </div>
                            <div class="filter-select _staff mat-select hidden-mob">
                                <app-select 
                                    placeholder="Staff" 
                                    [(value)]="selected_staff"
                                    (valueChange)="aps.refresh()"
                                    [items]="us.allowed_appointments" 
                                    [clearable]="true" 
                                    [searchable]="true"
                                    [multiple]="true"
                                    bindLabel="name" 
                                    bindValue="_id"
                                ></app-select>
                            </div>
                            <div class="filter-select _category mat-select hidden-mob">
                                <app-select 
                                    placeholder="Services" 
                                    [(value)]="services"
                                    (valueChange)="aps.refresh()"
                                    [items]="ss.all_services" 
                                    [clearable]="true" 
                                    [searchable]="true"
                                    [multiple]="true"
                                    bindLabel="name" 
                                    bindValue="_id"
                                ></app-select>
                            </div>
                            <div class="filter-select mat-select hidden-mob">
                                <app-select 
                                    placeholder="Status" 
                                    [(value)]="statuses"
                                    (valueChange)="aps.refresh()"
                                    [items]="selectStatuses" 
                                    [clearable]="true" 
                                    [searchable]="true"
                                    [multiple]="true"
                                ></app-select>
                            </div>
                        </div>
                        <div class="dis-mob ai-c nav-filter">
                            <button class="btn-icon page-nav__btn" style="margin-right: 10px;">
                                <span class="material-icons" *ngIf="direction=='desc'" (click)="direction='asc'">arrow_downward</span>
                                <span class="material-icons" *ngIf="direction=='asc'" (click)="direction='desc'">arrow_upward</span>
                            </button>
                            <div class="filter-mobile" (clickOutside)="filterShow=false">
                                <button class="btn-icon page-nav__btn" (click)="filterShow=!filterShow;"><span class="material-icons">filter_alt</span></button>
                                <div class="filter-mobile-overlay" *ngIf="filterShow" (click)="$event.stopPropagation();">
                                    <div class="waw-datepicker col-1 _arrow">
                                        <div style="display: block !important;" class="waw-datepicker__title">Date</div>
                                        <datepicker [date]="date" (valueChange)="date=$event; aps.refresh();"></datepicker>
                                    </div>
                                    <div class="col-1">
                                        <div style="display: block !important;" class="waw-datepicker__title">Staff</div>
                                        <app-select 
                                            placeholder="Staff" 
                                            [(value)]="selected_staff"
                                            (valueChange)="aps.refresh()"
                                            [items]="us.allowed_appointments" 
                                            [clearable]="true" 
                                            [searchable]="true"
                                            [multiple]="true"
                                            bindLabel="name" 
                                            bindValue="_id"
                                        ></app-select>
                                    </div>
                                    <div class="col-1">
                                        <div style="display: block !important;" class="waw-datepicker__title">Service</div>
                                        <app-select 
                                            placeholder="Services" 
                                            [(value)]="services"
                                            (valueChange)="aps.refresh()"
                                            [items]="ss.all_services" 
                                            [clearable]="true" 
                                            [searchable]="true"
                                            [multiple]="true"
                                            bindLabel="name" 
                                            bindValue="_id"
                                        ></app-select>
                                    </div>
                                    <div class="col-1">
                                        <div style="display: block !important;" class="waw-datepicker__title">Status</div>
                                        <app-select 
                                            placeholder="Status" 
                                            [(value)]="statuses"
                                            (valueChange)="aps.refresh()"
                                            [items]="selectStatuses" 
                                            [clearable]="true" 
                                            [searchable]="true"
                                            [multiple]="true"
                                        ></app-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="event" *ngIf="(aps.upcoming[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction).length">
                            <div class="event-title" (click)="comingOpen=!comingOpen">
                                <div class="event-title__type">Upcoming <span>({{(aps.upcoming[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction).length}})</span></div>
                                <div><span [class._active]="!comingOpen" class="icon-chevron"></span></div>
                            </div>
                            <div class="event-body" *ngIf="!comingOpen" [@flyInOut]="comingOpen">
                                <div class="event-row" *ngFor="let appointment of aps.upcoming[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction" (click)="edit_appointment(appointment)">
                                    <div class="event-row__color" [ngStyle]="{background: ss._services[appointment.service]?.color}"></div>
                                    <div class="event-row__body">
                                        <div class="event-row__date">{{appointment.day.singleDate.jsDate|date:'d MMM'}}</div>
                                        <div class="event-row__title">
                                            <div class="event-row__title__top">
                                                <div class="event-row__title__name">{{ss._services[appointment.service]?.name}}</div>
                                                <div class="event-row__title__status">{{appointment.status}}</div>
                                            </div>
                                            <div class="event-row__title__bottom">{{appointment.day.singleDate.jsDate|date:'EEEE'}} {{appointment.start}}-{{appointment.end}}</div>
                                        </div>
                                    </div>
                                    <div class="event-row__price">$ {{ss._services[appointment.service]?.price||0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="event" *ngIf="(aps.past[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction).length">
                            <div class="event-title" (click)="pastOpen=!pastOpen">
                                <div class="event-title__type">past <span>({{(aps.past[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction).length}})</span></div>
                                <div><span [class._active]="!pastOpen" class="icon-chevron"></span></div>
                            </div>
                            <div class="event-body" *ngIf="!pastOpen" [@flyInOut]="pastOpen">
                                <div class="event-row" *ngFor="let appointment of aps.past[us.user?._id] | appointmentsFilter: services: date?.singleDate?.formatted: aps.now: statuses: selected_staff ||[]: direction" (click)="edit_appointment(appointment)">
                                    <div class="event-row__color" [ngStyle]="{background: ss._services[appointment.service]?.color}"></div>
                                    <div class="event-row__body">
                                        <div class="event-row__date">{{appointment.day.singleDate.jsDate|date:'d MMM'}}</div>
                                        <div class="event-row__title">
                                            <div class="event-row__title__top">
                                                <div class="event-row__title__name">{{ss._services[appointment.service]?.name}}</div>
                                                <div class="event-row__title__status">{{appointment.status}}</div>
                                            </div>
                                            <div class="event-row__title__bottom">{{appointment.day.singleDate.jsDate|date:'EEEE'}} {{appointment.start}}-{{appointment.end}}</div>
                                        </div>
                                    </div>
                                    <div class="event-row__price">$ {{ss._services[appointment.service]?.price||0}}</div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div *ngIf="tab==4" class="profile-appoint">
                        <ng-container *ngIf="!settings.connected">
                            <div class="empty-email-template"><span class="icon-click-info"></span> You are not connected to gmail, please login in</div>
                            <div class="ta-c"><a class="waw-btn _primary _primary--mob c-white">Connect</a></div>
                            <p style="text-align: center; color: #E47878">The function is not working correctly and is temporarily disabled.</p>
                            <!-- <div class="ta-c"><a class="waw-btn _primary _primary--mob c-white" href="/api/google/connect">Connect</a></div> -->
                        </ng-container>
                        <ng-container *ngIf="settings.connected">
                            <div class="mt20 mb20 ta-r">
                                <button (click)="modal.show({component: 'email'})" class="waw-btn _primary _primary--mob">New Email</button>
                            </div>
                            <div class="table-wrapp">
                                <table class="table" cellpadding="0" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Subject</th>
                                            <th scope="col" class="w60">Body</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let email of us.emails[us.user.email]||[]">
                                            <td data-label="Title">{{email.subject}}</td>
                                            <td data-label="Text">
                                                <div class="text-overflow" (click)="email.showFull=!email.showFull" [class._active]="email.showFull" [innerHtml]="email.snippet"></div>
                                            </td>
                                            <td data-label="Date">{{email.created|date}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <div class="ta-c mt20 mb20">
                                    <button class="waw-btn _primary _primary--mob">Load more</button>
                                </div> -->
                            </div>
                            <div class="ta-c mt20 mb20"><button (click)="disable()" class="waw-btn _danger" *ngIf="us.is.admin||us.is.owner">Log Out</button></div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <files *ngIf="link==1" (linkEvent)="link = 0"></files>
            <notes *ngIf="link==2" (linkEvent)="link = 0"></notes>
            <questionnaire *ngIf="link==3" (linkEvent)="link = 0"></questionnaire>
        </div>
    </div>
</div>
