import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {
	transform(timelogs:any, from, to, refresh?:any, type = 1){
		if(!timelogs ) return type == 1 ? '0h' : '0:00';
		let minutes = 0;
		for (let i = 0; i < timelogs.length; i++){
			if(!timelogs[i].end) continue;
			if(isNaN(timelogs[i].created.getTime())) continue;
			if(isNaN(timelogs[i].end.getTime())) continue;
		    if(timelogs[i].created.getTime()>=from &&
		    	timelogs[i].end.getTime()<=to){
		    	minutes += (timelogs[i].end.getTime()-timelogs[i].created.getTime()) / 1000 / 60;
		    }
		}
		return type == 1 ? ( Math.floor(minutes/60)+'h ' + Math.floor(minutes%60) + 'm' ) : (Math.floor(minutes/60)+':' + Math.floor(minutes%60).toString().padStart(2, '0'));
	}
}
