import { Component, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, AppointmentService, CheckoutService, LocationService, ClosedService, LoaderService } from '@services';
import { AppointmentsDatePipe, CheckoutsDateLocationPipe } from '@pipes';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MongoService } from 'wacom';

@Component({
	selector: 'app-dashboard-staff',
	templateUrl: './dashboard-staff.component.html',
	styleUrls: ['./dashboard-staff.component.scss', '../dashboard.component.scss']
})
export class DashboardStaffComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public occupancyColumns: any = [
		{
			title: 'staff member',
			field: 'staff_name'
		}, {
			title: 'occupancy',
			field: 'occupancy'
		}, {
			title: 'visit time',
			field: 'visit_minutes'
		}, {
			title: 'working hours',
			field: 'working_minutes'
		}
	];

	public occupancyRows: any = [];

	public maxYValue: number = 0;

	public tipLineChart: any = [
		{
			"name": "Total",
			"series": []
		}, {
			"name": "Average",
			"series": []
		}
	];

	public yAxisTip = this.yAxisTipFormatting.bind(this);

	public totalTips = {
		current: '0.00',
		difference: '0%'
	};

	public averageTip = {
		current: '0.00',
		difference: '0%'
	};

	public revenueTipColumns: any = [
		{
			title: 'staff member',
			field: 'staff_name'
		}, {
			title: 'appointments',
			field: 'appointments'
		}, {
			title: 'services revenue',
			field: 'revenue'
		}, {
			title: 'total tips',
			field: 'sum_tips'
		}, {
			title: 'average tip',
			field: 'avg_tip'
		}
	];

	public revenueTipRows: any = [];

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
		occupancy: false,
		tipLineChart: false,
		tips: false,
		revenueTip: false,
	};
	
	constructor(
		public us: UserService, 
		public aps: AppointmentService, 
		private appointmentsDatePipe: AppointmentsDatePipe,
		public cs: CheckoutService,
		private datePipe: DatePipe,
		private mongo: MongoService,
		private currencyPipe: CurrencyPipe,
		private checkoutsDateLocationPipe: CheckoutsDateLocationPipe,
		private loc: LocationService,
		private cls: ClosedService,
		private loader: LoaderService,
		private eref: ElementRef
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
		
		this.mongo.on('user location closed', () => {
			this.initializeOccupancy();
		});
		this.mongo.on('checkout', () => {
			this.initializeTipLineChart();
			this.initializeTips();
		});
		this.mongo.on('user appointment checkout', () => {
			this.initializeRevenueTip();
		});
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			this.informationLoaded = {
				occupancy: false,
				tipLineChart: false,
				tips: false,
				revenueTip: false,
			};
	
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					this.refresh();
				}
			}, 10);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user appointment location closed checkout', () => {
			this.afterViewInit = true;
			let waitForInformationLoading = setInterval(() => {
				if (Object.values(this.informationLoaded).every(value => value === true)) {
					clearInterval(waitForInformationLoading);
					this.loader.remove();
				}
			}, 1);
		});
	}
	
	refresh() {
		this.initializeOccupancy();
		this.initializeTipLineChart();
		this.initializeTips();
		this.initializeRevenueTip();

		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	initializeOccupancy() {
		this.occupancyRows = [];

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
			d.setHours(0, 0, 0, 0);
			let day = this.datePipe.transform(new Date(d), 'EEEE');
			let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
			
			for(let location of this.loc.locations) {
				if((!this.location && location.data.business_hours?.[day]?.length) || (this.location == location._id && location.data.business_hours?.[day]?.length)) {
					
					let closed = false;
					for (let i = this.cls.closeds.length-1; i >= 0; i--){			
						if(
							this.cls.closeds[i].locations.find(l => l == location._id) && 
							(
								(
									!this.cls.closeds[i].holiday &&
									new Date(this.cls.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
									new Date(this.cls.closeds[i].end?.singleDate?.formatted) >= new Date(d)
								) ||
								(
									this.cls.closeds[i].holiday &&
									this.cls.isHoliday(new Date(d), this.cls.closeds[i].holiday, this.cls.closeds[i].substitute )
								)
							)
						) {				
							closed = true;
							break;
						}
					} 

					if(!closed) {							
						for(let staff of this.us.allowed_appointments) {

							if(staff.location.find(l => l == location._id)) {
								let working_minutes = 0;

								if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
									working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
								} else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
									working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
								}

								if(!this.occupancyRows.find(r => r.staff._id == staff._id)) {
									this.occupancyRows.push({
										staff: staff,
										staff_name: staff.name,
										working_minutes: working_minutes,
										visit_minutes: 0,
										occupancy: 0
									});
								} else {
									this.occupancyRows.find(r => r.staff._id == staff._id).working_minutes += working_minutes;
								}
							}
						}
					}
				}
			}
		}

		const appointments = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: this.selector.start, end: this.selector.end}, this.location);
		for (let appt of appointments) {
			if(this.occupancyRows.find(r => r.staff._id == appt.user)) {
				this.occupancyRows.find(r => r.staff._id == appt.user).visit_minutes += appt.duration;
				if(this.occupancyRows.find(r => r.staff._id == appt.user).working_minutes > 0) {
					this.occupancyRows.find(r => r.staff._id == appt.user).occupancy = this.occupancyRows.find(r => r.staff._id == appt.user).visit_minutes ? Math.round(this.occupancyRows.find(r => r.staff._id == appt.user).visit_minutes * 100 / this.occupancyRows.find(r => r.staff._id == appt.user).working_minutes) : 0;
				} else {
					this.occupancyRows.find(r => r.staff._id == appt.user).occupancy = 0;
				}
			}
		}

		this.informationLoaded.occupancy = true;
	}

	formatMinutesToHoursAndMinutes(minutes) {
		if(minutes == 0) return '0h 0min';
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
	  
		return `${hours}h ${remainingMinutes}min`.trim();
	}

	yAxisTipFormatting(value) {
		return this.currencyPipe.transform(value);
	}

	initializeTipLineChart() {
		this.maxYValue = 0;
		this.tipLineChart = [
			{
				"name": "Total",
				"series": []
			}, {
				"name": "Average",
				"series": []
			}
		];

		switch(this.interval) {
			case 'day':
				for(let i = 0; i < 24; i++) {
					let sum = 0;
					let count = 0;

					this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location).forEach((obj) => {
						let date = new Date(obj.created_at);
						if (date.getHours() == i) {
							count++;
							sum += obj.tips || 0;
						}
					});
					let avg = count ? (sum / count) : 0;

					let d = new Date(this.selector.start);
					d.setHours(i, 0, 0);

					if(sum > this.maxYValue) this.maxYValue = sum;
					
					this.tipLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortTime'),
						value: sum,
						title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
					});
					this.tipLineChart[1].series.push({
						name: this.datePipe.transform(d, 'shortTime'),
						value: avg,
						title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
					});
				}
				break;
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let sum = 0;
					let count = 0;

					this.checkoutsDateLocationPipe.transform(this.cs.date, {start: d}, this.location).forEach((obj) => {
						count++;
						sum += obj.tips || 0;
					});
					let avg = count ? (sum / count) : 0;

					if(sum > this.maxYValue) this.maxYValue = sum;
					
					this.tipLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: sum,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
					this.tipLineChart[1].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: avg,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'month':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let sum = 0;
					let count = 0;

					this.checkoutsDateLocationPipe.transform(this.cs.date, {start: d}, this.location).forEach((obj) => {
						count++;
						sum += obj.tips || 0;
					});
					let avg = count ? (sum / count) : 0;

					if(sum > this.maxYValue) this.maxYValue = sum;
					
					this.tipLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: sum,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
					this.tipLineChart[1].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: avg,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
					let sum = 0;
					let count = 0;

					this.checkoutsDateLocationPipe.transform(this.cs.date, {start: d, end: end}, this.location).forEach((obj) => {
						count++;
						sum += obj.tips || 0;
					});
					let avg = count ? (sum / count) : 0;

					if(sum > this.maxYValue) this.maxYValue = sum;
					
					this.tipLineChart[0].series.push({
						name: this.datePipe.transform(d, 'MMM'),
						value: sum,
						title: this.datePipe.transform(d, 'MMMM')
					});
					this.tipLineChart[1].series.push({
						name: this.datePipe.transform(d, 'MMM'),
						value: avg,
						title: this.datePipe.transform(d, 'MMMM')
					});
				}
				break;
			case 'quarter':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 7)) {
					let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);
					let sum = 0;
					let count = 0;

					this.checkoutsDateLocationPipe.transform(this.cs.date, {start: d, end: end}, this.location).forEach((obj) => {
						count++;
						sum += obj.tips || 0;
					});
					let avg = count ? (sum / count) : 0;

					if(sum > this.maxYValue) this.maxYValue = sum;
					
					this.tipLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: sum,
						title: ((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
					});
					this.tipLineChart[1].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: avg,
						title: ((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
					});
				}
				break;
		}

		this.informationLoaded.tipLineChart = true;
	}

	initializeTips() {
		const previous: any = {};
		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				previous.start = date;
				previous.end = date;
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				previous.start = weekStart;
				previous.end = weekEnd;
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				previous.start = monthStart;
				previous.end = monthEnd;
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				previous.start = yearStart;
				previous.end = yearEnd;
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				previous.start = quarterStart;
				previous.end = quarterEnd;
				break;
		}

		let currentSum = this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location).reduce((accumulator, currentValue) => {
			return accumulator + (currentValue.tips || 0);
		}, 0);
		let currentAvg = this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location)?.length ? (currentSum / this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location)?.length) : 0;

		let previousSum = this.checkoutsDateLocationPipe.transform(this.cs.date, previous, this.location).reduce((accumulator, currentValue) => {
			return accumulator + (currentValue.tips || 0);
		}, 0);
		let previousAvg = this.checkoutsDateLocationPipe.transform(this.cs.date, previous, this.location)?.length ? (previousSum / this.checkoutsDateLocationPipe.transform(this.cs.date, previous, this.location)?.length) : 0;

		let differenceSum = '0%';
		if (previousSum) {
			const percentageChange = ((currentSum - previousSum) / previousSum) * 100;
			differenceSum = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
		} else if(currentSum){
			differenceSum = '+100%';
		} else {
			differenceSum = '0%';
		}

		let differenceAvg = '0%';
		if (previousAvg) {
			const percentageChange = ((currentAvg - previousAvg) / previousAvg) * 100;
			differenceAvg = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
		} else if(currentAvg){
			differenceAvg = '+100%';
		} else {
			differenceAvg = '0%';
		}

		this.totalTips = {
			current: this.addMoneyAbbreviation(currentSum),
			difference: differenceSum
		};
	
		this.averageTip = {
			current: this.addMoneyAbbreviation(currentAvg),
			difference: differenceAvg
		};

		this.informationLoaded.tips = true;
	}

	addMoneyAbbreviation(amount) {
		const abbreviations = ['', 'K', 'M', 'B', 'T'];
		let index = 0;
	  
		while (amount >= 1000 && index < abbreviations.length - 1) {
			amount /= 1000;
			index++;
		}
	  
		return amount.toFixed(2) + abbreviations[index];
	}

	initializeRevenueTip() {
		this.revenueTipRows = [];
		for(let staff of this.us.allowed_appointments) {
			if(staff.location.find(l => l == this.location) || !this.location) {
				this.revenueTipRows.push({
					staff_name: staff.name,
					staff: staff,
					appointments: 0,
					revenue: 0,
					sum_tips: 0,
					num_tips: 0,
					avg_tip: 0
				});
			}
		}

		for (let appt of this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location)) {
			if(this.revenueTipRows.find(r => r.staff._id == appt.user)) {
				this.revenueTipRows.find(r => r.staff._id == appt.user).appointments++;
			}
		}

		for (let checkout of this.checkoutsDateLocationPipe.transform(this.cs.date, this.selector, this.location)) {
			if(this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user)) {
				this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).revenue += checkout.appointment?.money?.total || 0;
				this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).sum_tips += checkout.tips || 0;
				this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).num_tips++;
				this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).avg_tip = this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).sum_tips / this.revenueTipRows.find(r => r.staff._id == this.aps._appointments[checkout.appointment?.id]?.user).num_tips;
			}
		}

		this.informationLoaded.revenueTip = true;
	}

	findIntersection(array1: any[], array2: any[]): number {
		const timeToMinutes = (time: string): number => {
			const [hours, minutes] = time.split(':').map(Number);
			return hours * 60 + minutes;
		}
		let intersectionMinutes = 0;
	
		for (const interval1 of array1) {
			const start1 = timeToMinutes(interval1.from);
			const end1 = timeToMinutes(interval1.to);
		
			for (const interval2 of array2) {
				const start2 = timeToMinutes(interval2.from);
				const end2 = timeToMinutes(interval2.to);
		
				// Calculate the intersection
				const intersectionStart = Math.max(start1, start2);
				const intersectionEnd = Math.min(end1, end2);
		
				// If there's a valid intersection, add it to the total intersection minutes
				if (intersectionStart < intersectionEnd) {
					intersectionMinutes += intersectionEnd - intersectionStart;
				}
			}
		}
	
		return intersectionMinutes;
	}
}