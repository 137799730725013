import { Injectable } from '@angular/core';
import { MongoService, AlertService, HttpService } from 'wacom';
import { UserService } from './user.service';
import { DatePipe } from '@angular/common';
@Injectable({
	providedIn: 'root'
})
export class CheckoutService {
	public checks: any = [];
	public _checks:any = {}
	public appointment: any = {};
	public date: any = {};
	public now;
	public types: any = ['Cash', 'Credit Card', 'Debit Card'];
	constructor(private mongo: MongoService, private alert: AlertService, private us: UserService, private http: HttpService, private datePipe: DatePipe) { 
		// this.getDateRange(new Date('2023-07-06'), new Date('2023-07-06'));
		this.checks = mongo.get('checkout', {
			populate: [{
				field: 'staff',
				part: 'user'
			}],
			// sort: this.mongo.sortDescId(),
			groups: {
				appointment: {
					field: (doc: any) => doc.appointment.id,
				},
				date: {
					field: (doc, cb) => {
						if(doc.created_at){
							cb(this.datePipe.transform(doc.created_at, 'shortDate'));
						}
					}
				}
			},
		}, (arr, obj) => {
			console.log(arr);
			this.mongo.on('user', () => {
				for(let check of this.checks) {
					check.nameCheck = check.staff?.name || this.us._users[check.staff?._id || check.staff]?.name || '';
				}
			});
			this.appointment = obj.appointment;
			this.date = obj.date;
			this._checks = obj;
			this.now = Date.now();
		});
	}
	create(checkout:any={}, cb:any=event=>{}) {
		if(checkout._id) return this.save(checkout);
		this.mongo.create('checkout', checkout, created=>{
			if(typeof cb === 'function') cb(created);
			this.now = Date.now();
			this.alert.show({
				text: 'Checkout has been added.'
			});
		}); 
	}
	update(checkout) {
		this.mongo.afterWhile(checkout, ()=> {
			this.save(checkout);
		});
	}
	save(checkout){
		this.mongo.update('checkout', checkout, ()=>{
			this.now = Date.now()
			this.alert.show({
				text: 'Checkout has been updated.'
			});
		});
	}
	delete(checkout) {
		this.mongo.delete('checkout', checkout, ()=>{
			this.now = Date.now()
			this.alert.show({
				text: 'Checkout has been deleted.'
			});
		});
	}
}
